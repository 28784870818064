import React, { useState, useRef, useEffect } from "react";
import BottomBanner from "../bottom-banner/BottomBanner";
import { Routes, Route } from "react-router-dom";

import SignUp from "../sign-up/SignUp";

// eslint-disable-next-line
import maplibregl from "!maplibre-gl"; // ! is important here
import maplibreglWorker from "maplibre-gl/dist/maplibre-gl-csp-worker";
import "./map.scss";
import 'maplibre-gl/src/css/maplibre-gl.css';
maplibregl.workerClass = maplibreglWorker;


export default function Map(props) {

    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng] = useState(8.433071117939605);
    const [lat] = useState(46.97564153438015);
    const [zoom] = useState(10);
    const [locations, setLocations] = useState(null);

    const [marker, setMarker] = useState(null);


    const [guessedLocation, setGuessedLocation] = useState({ lng: 8.378139477313681, lat: 47.02130121992707 });



    const initialLocations = [
        {
            image: "/images/lu.jpg",
            coordinates: { lat: 46.986716, lng: 8.615561 },

        },
        {
            image: "/images/ow.jpg",
            coordinates: { lat: 46.775914, lng: 8.345440 },
        },
        {
            image: "/images/nw.jpg",
            coordinates: { lat: 46.960910, lng: 8.523634 },
        },
        {
            image: "/images/eng.jpg",
            coordinates: { lat: 46.776673, lng: 8.381494 },

        },
        {
            image: "/images/ent.jpg",
            coordinates: { lat: 46.833881, lng: 7.904777 },

        },
        {
            image: "/images/ur.jpg",
            coordinates: { lat: 46.622170, lng: 8.529534 },
        },
        {
            image: "/images/sz.jpg",
            coordinates: { lat: 47.021514, lng: 8.726389 },

        },

    ]

    const ckwLocations = [
        {
            image: "https://bikegenoss.ch/wp-content/uploads/trail-arena-engelberg-ckw-stories.jpg",
            coordinates: { lng: 8.407988784657602, lat: 46.8150554306553 },
        },
        {
            image: "https://bikegenoss.ch/wp-content/uploads/Screenshot-from-2023-05-31-09-14-53.png",
            coordinates: { lng: 8.254328468736606, lat: 46.90363687113407 },
        },
        {
            image: "https://bikegenoss.ch/wp-content/uploads/Screenshot-from-2023-05-31-09-15-33.png",
            coordinates: { lng: 7.906357336787264, lat: 46.83508888288494 },
        },
    ]

    useEffect(() => {
        if (map.current) return;
        map.current = new maplibregl.Map({
            container: mapContainer.current,
            style:
                "https://api.maptiler.com/maps/ch-swisstopo-lbm/style.json?key=kBczIQHgnFMWlsd7Ygx7",
            center: [lng, lat],
            zoom: zoom,
        });
        map.current.dragRotate.disable();
        map.current.touchZoomRotate.disableRotation();

        const newMarker = new maplibregl.Marker({
            draggable: true,
            color: "#556482",
        }).setLngLat([guessedLocation.lng, guessedLocation.lat]).addTo(map.current);

        setMarker(newMarker);

        const shuffledLocations = initialLocations.sort(() => 0.5 - Math.random());

        // Get sub-array of first n elements after shuffled
        const selectedLocations = shuffledLocations.slice(0, 3);

        setLocations(selectedLocations);
        const urlParams = new URLSearchParams(window.location.search);
        const event = urlParams.get('event');
        console.log(event); // Output: engelberg
        if (event) {
            localStorage.setItem('event', event);
        }

    }, []);

    function onDragEnd() {
        var lngLat = marker.getLngLat();
        setGuessedLocation(lngLat);
    }


    if (marker) {
        marker.on('dragend', onDragEnd);
    }


    if (map.current) {
        map.current.on('click', function (e) {
            setGuessedLocation(e.lngLat);
            marker.setLngLat(e.lngLat);
        });
    }




    return (
        <div className="map-wrap" id="map-wrap">
            <div ref={mapContainer} className="map" />

            <Routes>
                <Route path="/signup" element={<SignUp />} />

                <Route path="/" element={locations ? <BottomBanner
                    locations={locations}
                    guessedLocation={guessedLocation}
                    map={map} /> : null} />
                <Route path="/ckw" element={ckwLocations ? <BottomBanner
                    locations={ckwLocations}
                    guessedLocation={guessedLocation}
                    map={map} type={"ckw"} /> : null} />
            </Routes>



        </div>
    );
}